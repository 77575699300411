import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from "@/views/Dashboard.vue";
import {USER_KEY} from "@/store/constants/users";
import Login from "@/views/Login.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        component: Login
    },
    {
        path: '/',
        name: 'Main',
        meta: {skipAuth: true},
        component: Home
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {skipAuth: false},
        component: Dashboard
    },
    {
        path: "/http*",
        beforeEnter: to => {
            window.open(to.fullPath.substring(1), '_blank');
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
})

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    const publicPages = ['/login', '/booking', '/', '/catalogue','/https://www.donationalerts.com/r/boodkafm'];
    const authRequired = !publicPages.includes(to.path) && !to.meta?.skipAuth;
    const loggedIn = sessionStorage.getItem(USER_KEY);

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router
