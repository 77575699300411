

























































import {Component, Emit, Vue} from 'vue-property-decorator';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';

@Component({
  components: {}
})
export default class AppToolbar extends Vue {
  position = false;
  items = [
    {title: "Find within radius 3km", icon: "mdi-radius-outline", value: 3000, color: "deep-orange accent-1"},
    {title: "Find within radius 10km", icon: "mdi-radius-outline", value: 10000, color: "deep-orange accent-2"},
    {title: "Find within radius 50km", icon: "mdi-radius-outline", value: 50000, color: "deep-orange accent-3"},
    {title: "Find within radius 100km", icon: "mdi-radius-outline", value: 100000, color: "deep-orange accent-4"},
    {title: "Find within radius 200km", icon: "mdi-radius-outline", value: 200000, color: "deep-orange accent-5"},
  ]

  @Emit('gps-position')
  gpsPosition() {
    this.position = !this.position;
    return this.position;
  }

  @Emit('draw-circle')
  drawCircle(item: any) {
    return item.value;
  }
}
