


















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import {Fill, Stroke, Style} from "ol/style";
import CircleStyle from "ol/style/Circle";
import Geolocation from "ol/Geolocation";
import Feature from "ol/Feature";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Circle, Point} from "ol/geom";
import {Coordinate, createStringXY} from "ol/coordinate";
import {MousePosition} from "ol/control";
import {METERS_PER_UNIT} from "ol/proj/epsg4326";
import {Extent} from "ol/extent";
import {getPointResolution} from "ol/proj";

@Component({
  components: {}
})
export default class MapComponent extends Vue {

  @Prop()
  radius!: number;

  map: Map | undefined;
  view!: View;
  positionFeature = new Feature();
  geolocation: Geolocation | undefined;
  apiKey = '38a12378-6c49-471c-8f05-f9aa77e96c72';
  trackingMode = false;
  gpsAvailable = false;

  featureLayer: VectorLayer<any> | undefined;

  mousePositionControl = new MousePosition({
    coordinateFormat: createStringXY(4),
    projection: 'EPSG:4326',
    className: 'custom-mouse-position',
    target: document.getElementById('mouse-position')!,
  });


  init(): void {
    this.initView();
    this.initPositionFeature();
    this.initGeolocation();
    this.featureLayer = this.getDrawingLayer()
    this.map = new Map({
      target: 'map',
      layers: [this.baseLayer(), this.positionLayer(), this.featureLayer],
      view: this.view
    });
  }


  zoomOut(): void {
    const view = this.map!.getView();
    const zoom = this.view.getZoom();
    this.controlZoom(view.getCenter()!, zoom! - 1)
  }

  zoomIn(): void {
    const view = this.map!.getView();
    const zoom = this.view.getZoom();
    this.controlZoom(view.getCenter()!, zoom! + 1)
  }

  initView(): void {
    this.view = new View({
      center: [25.1700, 58.9000],
      zoom: 2
    })
  }

  initGeolocation(): void {
    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: this.view.getProjection(),
    });

    this.geolocation!.on('change', () => {
      this.geolocation!.getPosition() ? this.gpsAvailable = true : this.gpsAvailable = false
    })

    this.geolocation!.on('change:position', () => {
      const coordinates = this.geolocation!.getPosition();
      this.positionFeature.setGeometry(coordinates ? new Point(coordinates) : undefined);
      if (this.trackingMode) {
        this.trackPosition(this.geolocation!.getPosition()!);
      }
    });

    this.geolocation.setTracking(true);

  }

  baseLayer(): TileLayer<any> {
    return new TileLayer({
      source: new XYZ({
        maxZoom: 20,
        url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}.png?api_key=' + this.apiKey
      })
    })
  }

  positionLayer(): VectorLayer<any> {
    return new VectorLayer({
      source: new VectorSource({
        features: [this.positionFeature],
      }),
    });
  }

  getDrawingLayer(): VectorLayer<any> {
    return new VectorLayer({
      source: new VectorSource({wrapX: false}),
      visible: true,
      style: new Style(
          {
            stroke: new Stroke({
              color: '#BF360C',
              width: 2,
              lineDash: [10, 10]
            }),
          }
      )
    });
  }

  initPositionFeature(): void {
    this.positionFeature.setStyle(
        new Style({
          image: new CircleStyle({
            radius: 8,
            fill: new Fill({
              color: '#FF5722',
            }),
            stroke: new Stroke({
              color: '#BF360C',
              width: 4,
            }),
          }),
        })
    );
  }


  centerPosition() {
    this.trackingMode = !this.trackingMode;
    this.trackPosition(this.geolocation!.getPosition()!);
  }

  controlZoom(center: Coordinate, zoom: number): void {
    this.view.animate({
      center: center,
      duration: 300,
      zoom: zoom
    });
  }

  trackPosition(center: Coordinate): void {
    this.view.animate({
      center: center,
      duration: 2000,
      zoom: 18
    });
  }

  @Watch('radius')
  watchRadius(val) {
    this.drawCircleInMeter(val, this.geolocation?.getPosition()!)
  }

  drawCircleInMeter(radius: number, coordinate: Coordinate): void {
    if (!this.gpsAvailable) return;

    const circleFeature =
        new Feature({
          geometry: new Circle([coordinate[0], coordinate[1]], this.calcRadius(radius, coordinate)),
        });
    this.featureLayer?.getSource().clear();
    this.featureLayer!.getSource().addFeature(circleFeature);
    this.fitByExtent(circleFeature.getGeometry()?.getExtent()!, coordinate, {
      duration: 700,
      padding: [60, 60, 60, 60]
    })
  }

  private calcRadius(radius: number, coordinate: Coordinate): number {
    const projection = this.view.getProjection();
    const resolutionAtEquator = this.view.getResolution();
    const pointResolution = getPointResolution(projection, resolutionAtEquator!, coordinate);
    const resolutionFactor = resolutionAtEquator! / pointResolution;
    return (radius / projection.getMetersPerUnit()!) * resolutionFactor;
  }

  public fitByExtent(ext: Extent, center: Coordinate, optOptions?: any): void {
    const options = {...optOptions};
    if (this.view) {
      this.view.fit(ext, options);
    }
  }

  mounted() {
    this.init();
  }
}
