<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {},

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
#app {
  background-color: var(--v-background-base);
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

$font-family: 'Open Sans';

.v-application {
  [class*='text-'] {
    font-family: $font-family, sans-serif !important;
  }

  font-family: $font-family, sans-serif !important;
}

.ol-zoom {
  display: none !important;
}

</style>
