







import {Component, Vue} from 'vue-property-decorator';
import MapComponent from "@/components/MapComponent.vue";
import AppToolbar from "@/components/AppToolbar.vue";

@Component({
  components: {AppToolbar, MapComponent}
})
export default class Home extends Vue {
  radius = 0;

  drawCircle(event): void {
    this.radius = event
  }

}
